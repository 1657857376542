<template>
  <header
    class="fixed top-0 flex flex-col inset-x-0 z-50 bg-[#fdfdfd]"
    :class="{ 'h-screen': isShown }"
  >
    <div class="h-full flex flex-col lg:border-b-2 lg:border-b-[#F1F1F1]">
      <div class="container py-3 flex items-center justify-between">
        <a href="https://pony.market/" alt="" class="mr-6">
          <BaseSvgNew name="logo-pony" class="h-6 w-[72px] text-green-basic" />
        </a>
        <!-- <BaseButton
          v-else-if="!lgUp"
          class="navigation-button"
          label="Личный кабинет"
          @click="navigateToClient"
        /> -->
        <!-- <div
        class="flex items-center mr-6 pl-6 border-l-[1.5px] border-[#F1F1F1] text-sm leading-[18px] text-[#727272]"
      >
        <div class="h-4 w-6 rounded overflow-hidden mr-2">
          <img :src="countries[0].flag_url" class="object-contain w-full" />
        </div>
        {{ countries[0].label }}
        <BaseSvgNew name="chevron-down" class="h-5 w-5 ml-0.5" />
      </div> -->
        <LandingHeaderNavigation
          v-if="lgUp"
          class="pl-6 border-l-2 border-[#F1F1F1]"
          :is-show="isShown"
          :navigation="navigation[0]"
          @redirect="onRedirectHandler"
        />
        <div class="flex items-center ml-auto">
          <a
            v-if="!lgUp"
            href="https://pony.market/clientapp/"
            class="relative w-10 h-10 flex items-center justify-center ml-3 border-2 rounded-full border-solid border-green-basic xl:hidden"
          >
            <BaseSvgNew name="person" class="h-5 w-5 text-green-basic" />
          </a>
          <BaseButton
            v-if="lgUp"
            type="secondary"
            label="Личный кабинет"
            class="navigation-button ml-3"
            @click="navigateToClient"
          />
          <BaseBurgerButton
            v-if="!lgUp"
            ref="burgerButtonRef"
            class="ml-3"
            :is-shown="isShown"
            @click="toggleMenu"
          />
        </div>
      </div>
      <div
        v-if="!lgUp && isShown"
        ref="navigationMobileRef"
        class="flex flex-col flex-1 overflow-y-auto custom-scrollbar-hide bg-main"
      >
        <LandingHeaderNavigationMobile
          class="container py-6"
          :is-show="isShown"
          :navigation="navigation.flat()"
          @redirect="onRedirectHandler"
        />
      </div>
    </div>
    <div class="hidden container lg:block">
      <ul class="flex items-center py-3.5 text-sm leading-[18px] font-bold">
        <li
          v-for="item in navigation[1]"
          :key="item.name"
          class="lg:mr-9 last:mr-0"
        >
          <NuxtLink
            :to="item.href"
            class="text-[#727272] whitespace-nowrap transition-colors hover:text-green-basic"
          >
            {{ item.title }}
          </NuxtLink>
        </li>
      </ul>
    </div>
  </header>
</template>

<script setup lang="ts">
import { useBreakpoint } from '~/composables/breakpoints';
import BaseBurgerButton from '~/components/Base/BaseBurgerButton.vue';

type NavItem = {
  name: string;
  href: string;
  title: string;
};

const route = useRoute();
const { lgUp } = useBreakpoint();

const navigation: NavItem[][] = [
  [
    { name: 'steps', href: '/#steps', title: 'Как покупать' },
    { name: 'delivery', href: '/#delivery', title: 'О доставке' },
    {
      name: 'exchange-rate',
      href: '/#exchange-rate',
      title: 'Заявка на покупку',
    },
    {
      name: 'mail-forwarding',
      href: '/#mail-forward',
      title: 'Мой адрес за границей',
    },
  ],
  [
    { name: 'shops', href: '/shops', title: 'Популярные магазины' },
    // { name: 'catalog', href: '/catalog', title: 'Каталог товаров' },
    { name: 'calculator', href: '/shipping-calculator', title: 'Калькулятор' },
  ],
];

const isShown = ref<boolean>(false);

const burgerButtonRef = ref<typeof BaseBurgerButton>();
const navigationMobileRef = ref<HTMLDivElement>();

// onClickOutside(
//   navigationMobileRef,
//   () => {
//     isShown.value = false;
//   },
//   { ignore: [burgerButtonRef as any] },
// );

const toggleMenu = () => {
  isShown.value = !isShown.value;
};

watch(
  () => [lgUp.value, route.path],
  () => {
    isShown.value = false;
  },
);

watchEffect(() => {
  isShown.value
    ? document.body.classList.add('overflow-hidden')
    : document.body.classList.remove('overflow-hidden');
});

const navigateToClient = () => {
  location.assign('https://pony.market/clientapp/');
};

const onRedirectHandler = () => {
  isShown.value = false;
};
</script>

<style scoped lang="scss">
.navigation-button {
  @apply py-[7px];
}
</style>
