<template>
  <div class="flex flex-col max-lg:items-center">
    <a href="mailto:minimarket@ponyexpress.ru" class="footer-content">
      minimarket@ponyexpress.ru
    </a>
    <a href="tel:+79151477707" class="footer-content">+7-915-147-77-07</a>
  </div>
</template>

<style scoped lang="scss">
.footer-content {
  @apply mb-2 text-aluminum font-normal text-base leading-6 hover:text-white transition-colors;
}
</style>
