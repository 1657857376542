import LandingFooterContacts from './LandingFooterContacts.vue';
<template>
  <footer class="bg-black py-8 md:pb-6 md:pt-9 lg:pt-12">
    <div class="container flex flex-col items-center">
      <div class="w-full md:flex md:justify-between">
        <div class="footer-main max-md:mb-6">
          <p class="footer-title">Компания</p>
          <LandingFooterCompany />
        </div>
        <div class="footer-main max-md:mb-6">
          <p class="footer-title">Информация</p>
          <LandingFooterInfo />
        </div>
        <div class="footer-main">
          <p class="footer-title">Полезное</p>
          <LandingFooterUseful />
        </div>
        <div v-if="lgUp" class="footer-main">
          <p class="footer-title">Присоединяйтесь</p>
          <LandingFooterSocial class="mb-5 w-fit" />
          <LandingFooterContacts />
        </div>
      </div>
      <div v-if="!lgUp" class="footer-main items-center mt-12">
        <LandingFooterSocial class="mb-5 w-fit" />
        <LandingFooterContacts />
      </div>
      <div class="mt-12 flex items-center justify-center">
        <a
          href="https://pony.market"
          class="text-center text-aluminum font-normal text-base leading-6 hover:text-white transition-colors"
        >
          © Pony.Market 2024
        </a>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const { lgUp } = useBreakpoint();
</script>

<style scoped>
.footer-main {
  @apply flex flex-col;
}

.footer-title {
  @apply text-white font-bold text-xl leading-7 mb-5;
}
</style>
