<template>
  <Transition name="modal-transition">
    <div v-if="modal" class="modal-wrapper">
      <slot />
      <BaseModalPopup
        class="w-full max-w-[856px]"
        :title="modal.modalProps?.title"
        :style="{
          height: modal.modalProps?.size?.height,
          maxWidth: modal.modalProps?.size?.width,
        }"
        @close="closeModal"
      >
        <component
          :is="modal.component"
          v-bind="modal.modalProps?.componentProps"
          v-on="modal?.modalProps?.componentEvents"
          @close="closeModal"
        />
      </BaseModalPopup>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { modalInjectionKey } from '~/core/injectionsKeys';
import { ModalProvider } from '~/core/types';

const { modal, closeModal } = inject(modalInjectionKey, {}) as ModalProvider;

watchEffect(() => {
  if (!modal?.value) {
    document.body.classList.remove('overflow-hidden');
  } else {
    document.body.classList.add('overflow-hidden');
  }
});
</script>

<style scoped lang="scss">
.modal-wrapper {
  @apply fixed inset-y-0 inset-x-0 z-[999];
  @apply flex items-center justify-center px-4 py-8 overflow-y-auto;
  @apply bg-[rgba(0,0,0,0.6)];
}

.modal-transition-enter-active,
.modal-transition-leave-active {
  @apply opacity-0;
  @apply transition-opacity duration-500;
}

.modal-transition-enter,
.modal-transition-leave-to {
  @apply opacity-0;
}
</style>
